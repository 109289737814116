import { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import "./bmc.css"
import MyNavbar from './MyNavbar';

const API_URL = "https://bmc.loc-em.net/api"

const BusinessModelCanvas = ({id}) => {

  const [bmc, setBMC] = useState({
    keyPartners: "",
    keyActivities: "",
    keyResources: "",
    valuePropositions: "",
    customerRelationships: "",
    channels: "",
    customerSegments: "",
    costStructure: "",
    revenueStreams: "",
  });

  useEffect(() => {
    fetchBMC();
  }, []);

  const fetchBMC = async () => {
    try {
      const response = await fetch(`${API_URL}/bmc/${id}`);
      if (response.ok) {
        const data = await response.json();
        setBMC(data);
      }
    } catch (error) {
      console.error('Erreur lors du chargement du BMC:', error);
    }
  };

  // Fonction qui intercepte Ctrl+B et applique le style gras
  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
      e.preventDefault();
      document.execCommand('bold'); // Applique la mise en gras
    }
  };




  const saveText = async (section, newContent) => {
    const updatedBMC = { ...bmc, [section]: newContent };
    setBMC(updatedBMC);
    try {
      const response = await fetch(`${API_URL}/bmc/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBMC),
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde');
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du BMC:', error);
    }
  };

  const MyCardBody = ({ sectionName }) => {

    const divRef = useRef(null)

    const handleBlur = (e) => {
      saveText(sectionName, divRef.current.innerHTML)
    }

    return (
      <Card.Body>
        <div 
        contentEditable 
        ref={divRef} 
        onKeyDown={handleKeyDown} 
        onBlur={handleBlur} 
        dangerouslySetInnerHTML={{ __html: bmc ? bmc[sectionName] : "" }} 
        />
      </Card.Body>
    )

  }

  return (
    <>
      <MyNavbar />
      <Container fluid className="p-4">
        <Row className="mb-4">
          <Col>
            <h1>Business Model Canvas</h1>
          </Col>
        </Row>

        <Row>
          {/* Partenariats clés */}
          <Col md={2}>
            <Card className="mb-4">
              <Card.Header className="bg-yellow text-white">Partenariats clés</Card.Header>
              <MyCardBody sectionName="keyPartners" />
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-4">
              <Card.Header className="bg-yellow text-white">Activités clés</Card.Header>
              <MyCardBody sectionName="keyActivities"/>
            </Card>
            <Card className="mb-4">
              <Card.Header className="bg-yellow text-white">Ressources clés</Card.Header>
              <MyCardBody sectionName="keyResources" />
            </Card>
          </Col>
          <Col md={2}>
            <Card className="mb-4">
              <Card.Header className="bg-secondary text-white">Proposition de valeurs</Card.Header>
              <MyCardBody sectionName="valuePropositions" />
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-4">
              <Card.Header className="bg-orange text-white">Relations avec les clients</Card.Header>
              <MyCardBody sectionName="customerRelationships" />
            </Card>
            <Card className="mb-4">
              <Card.Header className="bg-orange text-white">Canaux de distribution</Card.Header>
              <MyCardBody sectionName="channels" />
            </Card>
          </Col>

          <Col md={2}>
            <Card className="mb-4">
              <Card.Header className="bg-orange text-white">Segments de clientèle</Card.Header>
              <MyCardBody sectionName="customerSegments" />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Header className="bg-green text-white">Structure des coûts</Card.Header>
              <MyCardBody sectionName="costStructure" />
            </Card>
          </Col>

          <Col md={6}>
            <Card className="mb-4">
              <Card.Header className="bg-green text-white">Flux de revenus</Card.Header>
              <MyCardBody sectionName="revenueStreams" />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BusinessModelCanvas;
