import { Route, Routes } from "react-router-dom";
import BusinessModelCanvas from './components/BusinessModelCanvas'
import Home from "./pages/Home"
import 'bootstrap/dist/css/bootstrap.min.css';
import BMC from "./pages/BMC";


export default function App() {


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/bmc/:id" element={<BMC />} />
    </Routes>)

}