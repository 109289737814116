import { useParams } from "react-router-dom";
import BusinessModelCanvas from "../components/BusinessModelCanvas";

const BMC = () => {

    const params = useParams() || {} ;
    const id = params.id || undefined;

    return (
        <main>
            <BusinessModelCanvas id={id}/>
        </main>
    );
};

export default BMC;